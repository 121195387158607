import '../components/Contact/Contact.scss';
import ContactMail from '../components/Contact/ContactMail';

const ContactPage = () => {
	return (
		<>
			<ContactMail />
		</>
	);
};

export default ContactPage;
