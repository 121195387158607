import { useNavigate } from 'react-router-dom';

const navLinks = [
	{
		title: 'Główna',
		children: [
			{ name: 'Start', href: '/main' },
			{ name: 'Pokaz', href: '/main#present' },
			{ name: 'Nasza Oferta', href: '/main#list' },
		],
	},
	{
		title: 'Wizualizacja',
		children: [
			{ name: 'Start', href: '/visualize' },
			{ name: 'Galeria', href: '/visualize#gallery' },
		],
	},
	{
		title: 'Kontakt',
		children: [
			{ name: 'Start', href: '/contact' },
			{ name: 'Napisz do nas', href: '/contact#post' },
		],
	},
];

const FooterNavigation = () => {
	const navigate = useNavigate();

	const handleNavigateScroll = (url) => {
		const link = url.split('#');
		navigate(link[0], { replace: false });
		setTimeout(() => {
			const element = document.getElementById(link[1]);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			}
		}, 200);
	};

	return (
		<div className='footer__links'>
			{navLinks.map((item) => (
				<article key={item.title}>
					<h3>{item.title}</h3>
					<ul>
						{item.children.map((link) => (
							<li key={`${item.title}-${link.name}`}>
								<button
									onClick={() => handleNavigateScroll(link.href)}
									className='footer__link'>
									{link.name}
								</button>
							</li>
						))}
					</ul>
				</article>
			))}
		</div>
	);
};

export default FooterNavigation;
