import { useState } from 'react';

const VisualizeTile = ({ data }) => {
	const { title, images } = data;
	const [selectedImage, setSelectedImage] = useState(null);

	const openImage = (image) => {
		setSelectedImage((prev) => image);
	};

	const closeImage = () => {
		setSelectedImage((prev) => null);
	};

	return (
		<>
			<div className='visualize__tile'>
				<h3>{title}</h3>
				<div className='images__container'>
					{images.map((image) => (
						<div
							key={image.alt}
							className='visualize__tile--image'
							onClick={() => openImage(image)}>
							<img src={image.src} alt={image.alt} />
						</div>
					))}
				</div>
			</div>
			{selectedImage && (
				<div className='overlay' onClick={closeImage}>
					<img src={selectedImage.src} alt={selectedImage.alt} className='enlarged-image fade-in' />
				</div>
			)}
		</>
	);
};

export default VisualizeTile;
