import { useState } from 'react';

const ContactMail = () => {
	const [formData, setFormData] = useState({
		name: '',
		subject: '',
		message: '',
		email: '',
		phone: '',
	});

	const [status, setStatus] = useState();

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleOnSubmit = async (e) => {
		e.preventDefault();

		try {
			const response = await fetch('http://localhost/contact.php', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			});

			if (response.ok) {
				const result = await response.json();
				setStatus(result.message);
			}else {
				setStatus('Wystąpił błąd podczas wysłania wiadomości')
			}
		} catch (error) {
			setStatus('Wystąpił błąd podczas łączenia z serwerem.');
		}
	};

	return (
		<section id='post' className='contact'>
			<div className='section__text'>
				<h2>Napisz Wiadomość</h2>
				<p>Uzupełnij pola, a jak najszybciej się odezwiemy</p>
			</div>
			<form className='contact__post' onSubmit={handleOnSubmit}>
				<div className='form__row'>
					<div className='input__box'>
						<label htmlFor='name'>Imię i Nazwisko</label>
						<input name='name' type='text' onChange={handleChange} required />
					</div>
					<div className='input__box'>
						<label htmlFor='subject'>Wybierz Temat</label>
						<select name='subject' onChange={handleChange} required>
							<option>Zapytanie o wycenę druku</option>
							<option>Prośba o doradztwo w wyborze materiałów</option>
							<option>Współpraca i oferty biznesowe</option>
						</select>
					</div>
				</div>
				<div className='form__row'>
					<div className='input__box'>
						<label htmlFor='message'>Wiadomość</label>
						<textarea
							name='message'
							minLength={50}
							onChange={handleChange}
							required></textarea>
					</div>
				</div>
				<div className='form__row'>
					<div className='input__box'>
						<label htmlFor='email'>Email</label>
						<input name='email' onChange={handleChange} type='email' required />
					</div>
					<div className='input__box'>
						<label htmlFor='phone'>Numer Telefonu</label>
						<input
							name='phone'
							type='tel'
							pattern='[0-9]{3}-[0-9]{3}-[0-9]{3}'
							placeholder='123-456-789'
							maxLength={11}
							onChange={handleChange}
							required
						/>
					</div>
				</div>
				<div className='form__row form__button-reverse'>
					<button disabled type='submit'>Wyślij</button>
				</div>
				{status && <p>{status}</p>}
			</form>
		</section>
	);
};

export default ContactMail;
